import { Component, Input, SimpleChange, OnChanges, Optional } from "@angular/core";
import { DataTable, PageEvent } from "angular-6-datatable";

@Component({
  selector: "customMfPaginator",
  template: `<ng-content></ng-content>`
})
export class CustomPaginatorComponent implements OnChanges {

  @Input("mfTable") inputMfTable: DataTable;

  private mfTable: DataTable;

  public activePage: number;
  public rowsOnPage: number;
  public dataLength: number = 0;
  public lastPage: number;
  public pagesToShowStart: number;
  public pagesToShowEnd: number;
  public pagesToShow: number[];
  public restForEnd: number;
  public restForBegin: number;
  public showEllipseItem: boolean;

  public constructor(@Optional() private injectMfTable: DataTable) {
  }

  public ngOnChanges(changes: { [key: string]: SimpleChange }): any {
    this.mfTable = this.inputMfTable || this.injectMfTable;
    this.onPageChangeSubscriber(this.mfTable.getPage());
    this.mfTable.onPageChange.subscribe(this.onPageChangeSubscriber);
  }

  public setPage(pageNumber: number): void {
    this.mfTable.setPage(pageNumber, this.rowsOnPage);
  }

  public setRowsOnPage(rowsOnPage: number): void {
    this.mfTable.setPage(this.activePage, rowsOnPage);
  }

  private onPageChangeSubscriber = (event: PageEvent) => {
    this.activePage = event.activePage;
    this.rowsOnPage = event.rowsOnPage;
    this.dataLength = event.dataLength;
    this.lastPage = Math.ceil(this.dataLength / this.rowsOnPage);
    this.pagesToShowStart = this.limitStartPage(this.activePage);
    this.pagesToShowEnd = this.limitEndPage(this.activePage);
    this.addRestToBegin();
    this.addRestToEnd();
    this.cutToMax10();

    this.pagesToShow = [];
    for (let i = this.pagesToShowStart; i <= this.pagesToShowEnd; i++) {
      this.pagesToShow.push(i);
    }
    this.showEllipseItem = (this.pagesToShowEnd < this.lastPage);
  };

  private limitStartPage(page): number {
    let r = page - 5;
    if (r < 1) {
      this.restForEnd = Math.abs(r);
      r = 1;
    } else
      this.restForEnd = 0;
    return r;
  }

  private limitEndPage(page): number {
    let r = page + 5;
    if (r > this.lastPage) {
      this.restForBegin = Math.abs(this.lastPage - r);
      r = this.lastPage;
    } else
      this.restForBegin = 0;
    return r;
  }

  private addRestToBegin() {
    this.pagesToShowStart = this.pagesToShowStart - this.restForBegin;
    if (this.pagesToShowStart < 1)
      this.pagesToShowStart = 1;
  }

  private addRestToEnd() {
    this.pagesToShowEnd = this.pagesToShowEnd + this.restForEnd;
    if (this.pagesToShowEnd > this.lastPage)
      this.pagesToShowEnd = this.lastPage;
  }

  private cutToMax10() {
    if ((this.pagesToShowEnd - this.pagesToShowStart) >= 10)
      this.pagesToShowStart++;
  }

}