import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ScreenService } from 'src/app/services/screen.service';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

declare var $: any;

@Component({
  selector: 'app-thought-leadership',
  templateUrl: './thought-leadership.component.html',
  styleUrls: ['./thought-leadership.component.css']
})
export class ThoughtLeadershipComponent implements OnInit {

  imgURLprefix ='';
  constructor( private screenService: ScreenService, private router: Router) {
    this.imgURLprefix = this.screenService.getPathToImage();
   }


   ngOnInit() {
    /* slider */
    $('.projects-carousel').owlCarousel({
     loop:true,
     autoplay: true,
     responsiveClass:true,
     responsive:{
         0:{
             items:1,
             // nav:true
         }
     }
 });

  /* stop yputube videos */
 $('#myVideoModal .close').on('click', function(){      
     $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
 });
 
 $('#myVideoModal').on('hidden.bs.modal', function () {
     $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
  });
  $('html, body').animate({
    scrollTop: -100
}, 1);
}

scroll(id) {
    
    if(id== 'contactUs'){
      this.screenService.setSelectedParam(true); 
    }else{
      this.screenService.setSelectedParam(false); 
    }

    this.router.navigate(['']);
  }


}
