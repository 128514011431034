import { Component, OnInit } from '@angular/core';
import { ScreenService } from '../../services/screen.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  imgURLprefix = '';
  isAdminPortal = false;
  isInnovationPage = false;

  constructor(private screenService: ScreenService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.imgURLprefix = this.screenService.getPathToImage();

    // Check the URL to determine if the portal is micro-site or admin portal to show the header menu conditionally.

    router.events.subscribe((val) => {
      this.isAdminPortal = (window.location.href.indexOf('/innovation') > -1 || window.location.href.indexOf('/details') > -1 || window.location.href.indexOf('/dashboard') > -1 || window.location.href.indexOf('/adduser') > -1 || window.location.href.indexOf('/rating') > -1 || window.location.href.indexOf('/unauthorized') > -1) ? true : false;
      this.isInnovationPage = (window.location.href.indexOf('/innovation') > -1) ? true : false || (window.location.href.indexOf('/') > -1) ? true : false;
    });

    // On refresh of the page/tab, scroll the page to top
    $(window).on('beforeunload', function () {
      $(window).scrollTop(0);
    });
  }

  scroll(id) {
    console.log(id);
    this.screenService.setSelectedParam(false);
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const page = params['page'];
      let id = ''
      if (page != undefined) {
        if (page === 'services') {
          id = '#productOffering'
        } else if (page === 'work') {
          id = '#caseStudies'
        } else if (page === 'leadership') {
          id = '#thoughtLeadership'
        } else if (page === 'testimonials') {
          id = '#testimonials'
        } else if (page === 'media') {
          id = '#media'
        } else if (page === 'contact') {
          id = '#contactUs'
        }
        console.log(page)
        setTimeout(function () {
          $('html, body').animate({
            scrollTop: $(id).offset().top - 100
          }, 1000);
        }, 0);
      }
    });
  }

  closeMenu() {
    var navMain = $("#navbar");
    navMain.collapse('hide');
    var navSubMain = $("#subMenuOffering");
    navSubMain.collapse('hide');
  }

  ngOnDestroy() {
  }

  gotoSubmitIdea() {
    this.screenService.isEligibleToScrollTosubmitIdeaBlock = true;
    this.router.navigate(['innovation']);
    $('html, body').animate({
      scrollTop: $('#submitIdeas').offset().top - 265
    }, 1000);
  }

}
