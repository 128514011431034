import { Component, Input, OnChanges } from "@angular/core";
import { DataTable } from "angular-6-datatable";
import * as _ from "lodash";

@Component({
    selector: "customMfBootstrapPaginator",
    template: `
    <customMfPaginator #p [mfTable]="mfTable">
        <ul class="pagination" *ngIf="p.dataLength > p.rowsOnPage">
            <li class="page-item" *ngIf="p.activePage > 1" (click)="p.setPage(1)">
                <a class="page-link" style="cursor: pointer">❮</a>
            </li>
            <li *ngFor="let page of p.pagesToShow" class="page-item" [class.active]="p.activePage == page" (click)="p.setPage(page)">
                <a class="page-link" style="cursor: pointer">{{page}}</a>
            </li>      
            <li *ngIf="p.showEllipseItem" class="page-item">
                <a class="page-link">...</a>
            </li>          
            <li *ngIf="p.showEllipseItem" class="page-item" (click)="p.setPage(p.lastPage)">
                <a class="page-link" style="cursor: pointer">{{p.lastPage}}</a>
            </li>          
            <li class="page-item" *ngIf="p.activePage < p.lastPage" (click)="p.setPage(p.lastPage)">
                <a class="page-link" style="cursor: pointer">❯</a>
            </li>            
        </ul>       
    </customMfPaginator>
    `
})
export class CustomBootstrapPaginatorComponent implements OnChanges {
    @Input("rowsOnPageSet") rowsOnPageSet = [];
    @Input("mfTable") mfTable: DataTable;

    minRowsOnPage = 0;

    ngOnChanges(changes: any): any {
        if (changes.rowsOnPageSet) {
            this.minRowsOnPage = _.min(this.rowsOnPageSet)
        }
    }
}