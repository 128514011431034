import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenService } from '../../services/screen.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  imgURLprefix ='';
  constructor(private router: Router, private screenService: ScreenService) {
    this.imgURLprefix = this.screenService.getPathToImage();
   }

  ngOnInit() {
  }

  gobackInnovtion(){
    this.router.navigate(['innovation']);
  }
}
