import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BaComponent } from './components/ba/ba.component';
import { XdComponent } from './components/xd/xd.component';
import { QcComponent } from './components/qc/qc.component';
import { WebComponent } from './components/web/web.component';
import { SupportComponent } from './components/support/support.component';
import { ThoughtLeadershipComponent } from './components/thought-leadership/thought-leadership.component';
import { BlogComponent } from './components/blog/blog.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { ServiceComponent } from './components/service/service.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SealShopComponent } from './components/seal-shop/seal-shop.component'

const routes = [
    //{ path: '', component: InnovationComponent },
    { path: '', component: HomeComponent },
    { path: 'ba', component: BaComponent },
    { path: 'xd', component: XdComponent },
    { path: 'qc', component: QcComponent },
    { path: 'web', component: WebComponent },
    { path: 'support', component: SupportComponent },
    { path: 'sealShop', component: SealShopComponent }, //need to change to sealshop component 
    { path: 'thought-leadership', component: ThoughtLeadershipComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'mobile', component: MobileComponent },
    { path: 'service', component: ServiceComponent },
    { path: 'privacyNotice', component: PrivacyPolicyComponent },
    { path: 'cookiePrivacy', component: CookiePolicyComponent },
    { path: 'unauthorized', component: UnauthorizedComponent}
        
];
@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {

}