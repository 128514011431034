import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataTableModule } from "angular-6-datatable";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BaComponent } from './components/ba/ba.component';
import { XdComponent } from './components/xd/xd.component';
import { BlogComponent } from './components/blog/blog.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { QcComponent } from './components/qc/qc.component';
import { WebComponent } from './components/web/web.component';
import { ThoughtLeadershipComponent } from './components/thought-leadership/thought-leadership.component';
import { SupportComponent } from './components/support/support.component';
import { ServiceComponent } from './components/service/service.component';
import { DefaultSorterComponent } from './components/widgets/default-sorter/default-sorter.component';
import { CustomBootstrapPaginatorComponent } from './components/widgets/custom-paginator/custom-bootstrap-paginator/custom-bootstrap-paginator.component';
import { CustomPaginatorComponent } from './components/widgets/custom-paginator/custom-paginator/custom-paginator.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatMomentDatePipe } from './pipes/format-moment-date.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ToastrModule } from 'ngx-toastr';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { LatestProjectGalleryComponent } from './components/common/latest-project-gallery/latest-project-gallery.component';
import { WorkStatisticsShowcaseComponent } from './components/common/work-statistics-showcase/work-statistics-showcase.component';
import { SealShopComponent } from './components/seal-shop/seal-shop.component';

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BaComponent,
    XdComponent,
    BlogComponent,
    MobileComponent,
    QcComponent,
    WebComponent,
    ThoughtLeadershipComponent,
    SupportComponent,
    ServiceComponent,
    DefaultSorterComponent,
    CustomBootstrapPaginatorComponent,
    CustomPaginatorComponent,
    FilterPipe,
    FormatMomentDatePipe,
    CustomDatePipe,
    UnauthorizedComponent,
    SafePipe,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    LatestProjectGalleryComponent,
    WorkStatisticsShowcaseComponent,
    SealShopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DataTableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    FormsModule,
    HttpModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
