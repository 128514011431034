import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private barMessage = {
    type: 'info',
    message: "Example message",
    show: false,
    iconClass: ''
  };

  contactClicked = false;
  isEligibleToScrollTosubmitIdeaBlock = false;
  infoPopupSubject = new BehaviorSubject("");

  private timer;

  constructor() { }

  getBarMessage() {
    return this.barMessage;
  }

  getInfoPopupSubject() {
    return this.infoPopupSubject;
  }

  setBarMessage(message) {
    this.barMessage.type = message.type;
    this.barMessage.message = message.message;
    this.barMessage.show = message.show;
    this.barMessage.iconClass = message.iconClass;
    if (this.timer)
      clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hideBarMessage();
    }, 5500)
  }

  hideBarMessage() {
    this.barMessage.show = false;
  }

  showInfoMessage(message) {
    this.setBarMessage({ show: true, type: 'info', message: message, iconClass: 'icon-info1' });
  }

  showErrorMessage(message) {
    this.setBarMessage({ show: true, type: 'error', message: message, iconClass: 'icon-error' });
  }

  showSuccessMessage(message) {
    this.setBarMessage({ show: true, type: 'success', message: message, iconClass: 'icon-succes' });
  }

  showWarningMessage(message) {
    this.setBarMessage({ show: true, type: 'warning', message: message, iconClass: 'icon-warning' });
  }

  showLoader() {
    document.getElementById('smartstok-app-loader').style.display = 'block';
  }

  hideLoader() {
    document.getElementById('smartstok-app-loader').style.display = 'none';
  }


  showInfoPopup(message) {
    this.infoPopupSubject.next(message);
  }

  getPathToImage() {
    let addrURL = window.location.href;
    if (addrURL.length > 0) {
      if (addrURL.indexOf("/dev/") > -1) {
        return "/apps/dev/idcmicrosite/assets/images/";
      }
      else if (addrURL.indexOf("/qc/") > -1) {
        return "/apps/qc/idc/assets/images/";
      }
      else if (addrURL.indexOf("/apps/smartstok") > -1) {
        return "/apps/idc/assets/images/";
      }
      else if (addrURL.indexOf("localhost") > -1) {
        return "../../../assets/images/";
      }
      else {
        return "../../../assets/images/";
      }
    }
  }  

  checkBrowserSupport() {
    var ua = window.navigator.userAgent;
    var isChrome = ua.toLowerCase().indexOf('chrome') > -1;
    var isIE11 = ua.indexOf('Trident/7') > 0;
    var isEdge = ua.indexOf('Edge/') > 0;
    var isMobile = ua.indexOf('Mobile/') > 0;
    return isChrome || isIE11 || isEdge || isMobile;
  }

  setSelectedParam(flag){
    this.contactClicked = flag;
  }

  getSelectedParam(){
   return this.contactClicked;
  }
}

