import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScreenService } from 'src/app/services/screen.service';
import { DataService } from './services/data.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  barMessage;
  authenticationInProgress = true;
  authSubscribe: any;

  constructor(private translateService: TranslateService, private screenService: ScreenService, private router: Router) {
    translateService.setDefaultLang('en');
    this.barMessage = screenService.getBarMessage();
  }

  ngOnInit() {
    if (window.location.href.includes("85.190.192.40")) {
      window.location.href = "https://www.trelleborg.com/";
    }

    this.screenService.hideLoader();  

 
   /* open t-talk video stream in new tab */
   $('.visit-channel-btn').click(function () {
        var redirectWindow = window.open('https://web.microsoftstream.com/channel/fed4d15b-38c1-4d19-9b09-938cb67ad4ad', '_blank');
        redirectWindow.location;
    });
  }

  ngOnDestroy() {
  }

  changeOfRoutes() {     
  }
}


/* scroll effects */
$(window).scroll(function() {
  if($('.what-we-do').offset()){
    var hT = $('.what-we-do').offset().top,
    hH = $('.what-we-do').outerHeight(),
    wH = $(window).height(),
    wS = $(this).scrollTop();
  if (wS > (hT+hH-wH)){
    $('.showcase-item').css({'opacity': '1', 'margin-top': '0'})
  }
  }
 
});

/* scroll effects */
$(window).scroll(function() {
  if($('.showcase-wrap').offset()){
    var hT2 = $('.showcase-wrap').offset().top,
    hH2 = $('.showcase-wrap').outerHeight(),
    wH2 = $(window).height(),
    wS2 = $(this).scrollTop();
  if (wS2 > (hT2+hH2-wH2)){
    $('.how-wework-block').css({'opacity': '1', 'margin-top': '0'})
  }
  }
  
});
