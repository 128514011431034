import { Component, Input, OnInit } from "@angular/core";
import { DataTable, SortEvent } from "angular-6-datatable";


@Component({
  selector: "mfCustomDefaultSorter",
  template: `
        <a style="cursor: pointer; color: white;" (click)="sort()" class="text-nowrap">
            <ng-content></ng-content>
            <span *ngIf="!isSortedByMeAsc && !isSortedByMeDesc" class="glyphicon glyphicon-sort"></span>
            <span *ngIf="isSortedByMeAsc" class="glyphicon glyphicon-sort-by-attributes" aria-hidden="true"></span>
            <span *ngIf="isSortedByMeDesc" class="glyphicon glyphicon-sort-by-attributes-alt" aria-hidden="true"></span>
        </a>`
})
export class DefaultSorterComponent implements OnInit {

  @Input("by") sortBy: string;

  isSortedByMeAsc: boolean = false;
  isSortedByMeDesc: boolean = false;

  public constructor(private mfTable: DataTable) {
  }

  public ngOnInit(): void {
    this.mfTable.onSortChange.subscribe((event: SortEvent) => {
      this.isSortedByMeAsc = (event.sortBy == this.sortBy && event.sortOrder == "asc");
      this.isSortedByMeDesc = (event.sortBy == this.sortBy && event.sortOrder == "desc");
    });
  }

  sort() {
    if (this.isSortedByMeAsc) {
      this.mfTable.setSort(this.sortBy, "desc");
    } else {
      this.mfTable.setSort(this.sortBy, "asc");
    }
  }

}
