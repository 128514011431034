import { Component, OnInit } from '@angular/core';
import 'popper.js';
import 'datatables.net';
import 'datatables.net-bs';
import { DataService } from 'src/app/services/data.service';
import { ScreenService } from 'src/app/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { debug } from 'util';

declare var $: any;

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  imgURLprefix ='';
  captchaUrl = '/jcaptcha?datetime='+ new Date().getTime();

  constructor(private dataService: DataService, private screenService: ScreenService, private route: ActivatedRoute, private router: Router) {
    this.imgURLprefix = this.screenService.getPathToImage();


  }

  ngOnInit() {

  /* our work carousel */
    $(".our-work-carousel").owlCarousel({
        margin:10,
        responsiveClass:true,
        autoplay: true,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            700:{
                items:2
            },
            1000:{
                items:3
            },
            1200:{
                items:3
            }
        }
    });


     /* Header fixed onscroll */
    // $(window).scroll(function(){
    //     var sticky = $('header'),
    //         scroll = $(window).scrollTop();

    //     if (scroll >= 60) sticky.addClass('fixed');
    //     else sticky.removeClass('fixed');
    // });

    /* slider */
    $('.slider-carousel').owlCarousel({
      loop:true,
      autoplay: true,
      responsiveClass:true,
      responsive:{
          0:{
              items:1,
              // nav:true
          }
      }
  });

  /*-- Testimonial Carousel --*/
  $('.testimonial-carousel').owlCarousel({
      loop:true,
      margin:20,
      responsiveClass:true,
      autoplay: true,
      responsive:{
          0:{
              items:1
          },
          1000:{
              items:2
          }
      }
  });

  /* nav click menu */
  $('.navbar-nav li a').click(function(){
     var $href = $(this).attr('name');
     $('html, body').animate({
          scrollTop: $($href).offset().top -100
      }, 1000);
 });

  /* nav click menu */
  $('button.btn-lets-talk').click(function(){
      console.log($('#contactUs').offset().top, ">>>>>>>>.");
      $('html, body').animate({
           scrollTop: $('#contactUs').offset().top -100
       }, 1000);
  });

 /* stop yputube videos */
  $('#myVideoModal .close').on('click', function(){
    alert("close");
      $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
  });

  $('#myVideoModal').on('hidden.bs.modal', function () {
    alert("opem");
      $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
   });

  }

  ngAfterViewInit(): void {
    // if(this.screenService.getSelectedParam() == true ){
    //     let el = document.getElementById('contactUs');
    //     if(el != null){
    //     $('html, body').animate({
    //             scrollTop: $('#contactUs').offset().top -100
    //         }, 1000);

    //     }
    // }else{
    //     $('html, body').animate({
    //         scrollTop: -100
    //       }, 1);
    // }

    /* scrolling home page based on the URL */
    // var queryStringVal = window.location.href.split('?page=')[1].toLowerCase();
    // if(queryStringVal) {
    //     var urlVal = '';
    //     if(queryStringVal === 'services') {
    //         urlVal = '#productOffering';
    //     }
    //     else if(queryStringVal === 'work') {
    //         urlVal = '#caseStudies';
    //     }
    //     else if(queryStringVal === 'leadership') {
    //         urlVal = '#thoughtLeadership';
    //     }
    //     else if(queryStringVal === 'testimonials') {
    //         urlVal = '#testimonials';
    //     }
    //     else if(queryStringVal === 'media') {
    //         urlVal = '#media';
    //     }
    //     else if(queryStringVal === 'contact') {
    //         urlVal = '#contactUs';
    //     }
    //     setTimeout(function(){
    //         $('html, body').animate({
    //             scrollTop: $(urlVal).offset().top -100
    //         }, 1000);
    //     }, 0);
    // }
  }

  gotoUrl(url){
   this.router.navigate([url]);
  }



setImgURLinModalPopup(bigImg) {
  $('.popup-img').attr("src", this.imgURLprefix + bigImg);
}

submitContactForm() {
  var contactInfo = {
    username: $("#username").val(),
    emailId: $("#email").val(),
    subjectMessage: $("#message").val(),
    contactNum: $("#phone").val(),
    captcha: $("#captcha").val(),
  };
  // $('#contactMessage .msg-box').html('Sending...').css('margin-left', '0');
  if (
    $("#username").val() == "" ||
    $("#email").val() == "" ||
    $("#message").val() == "" ||
    $("#captcha").val() == ""
  ) {
    $("#errorMessage .msg-box")
      .html(
        '<span class="icon-close"></span> Please enter all mandatory fields.'
      )
      .css("margin-left", "0");
    $("#contactMessage .msg-box").css("margin-left", "-100%");
  } else {
    if (this.validateEmail($("#email").val())) {
      $("#errorMessage .msg-box").css("margin-left", "-100%");
      var serviceTargetURL = "";
      var currentUrl = window.location.href;
      if (currentUrl.indexOf("www.idc.trelleborg.com") > 0) {
        serviceTargetURL =
          "https://www.idc.trelleborg.com/appService/micrositecontroller/saveUserDetails";
      } else {
        serviceTargetURL =
          "https://idc.trelleborg.com/appService/micrositecontroller/saveUserDetails";
      }

      $.ajax({
        url: serviceTargetURL,
        type: "post",
        dataType: "json",
        contentType: "application/json",
        success: function (data) {
            if(data.serviceStatus){
              $("#contactMessage .msg-box")
              .html(
                '<span class="icon-stepper_check"></span> ' + data.serviceMessage
              )
              .css("margin-left", "0")
              .css("color", "#3dc143");
              $("#username").val(""),
              $("#email").val(""),
              $("#message").val(""),
              $("#phone").val("");
              $("#captcha").val("");
              $('.generated-captcha').attr("src", "/jcaptcha?datetime=" + new Date().getTime());
            }else{
              $("#contactMessage .msg-box")
              .html(
                '<span class="icon-close"></span> ' + data.serviceMessage
              )
              .css("margin-left", "0")
              .css("color", "#D54130");
              $("#captcha").val("");
              $('.generated-captcha').attr("src", "/jcaptcha?datetime=" + new Date().getTime());
            }
          setTimeout(function () {
            $("#contactMessage .msg-box").css("margin-left", "-100%");
          }, 4500);
        },
        data: JSON.stringify(contactInfo),
      });
    } else {
      $("#errorMessage .msg-box")
        .html('<span class="icon-close"></span> Please enter valid e-mail.')
        .css("margin-left", "0");
      $("#contactMessage .msg-box").css("margin-left", "-100%");
    }
  }
}

validateEmail(emailField){
  var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  console.log(reg.test(emailField))
  return reg.test(emailField);
}

checkFieldValidaions() {
    if($('#username').val() == '' && $('#email').val() == '' && $('#message').val() == '') {
        $('#errorMessage .msg-box').css('margin-left', '-100%');
    }
  }

  refreshCaptchaUrl() {
    $("#captcha").val("");
    this.captchaUrl = "/jcaptcha?datetime=" + new Date().getTime();
  }

}

// if(window.location.href.indexOf('testimonials') > -1) {
//     var hT = $('.testimonial-heading').offset().top,
//     hH = $('.testimonial-heading').outerHeight(),
//     wH = $(window).height(),
//     wS = $(this).scrollTop();
//     if (wS > (hT+hH-wH)){
//         $('.testimonial-carousel').css({'opacity': '1', 'transform':'translate(0, 0)', '-ms-transform': 'translate(0, 0)' ,
//     '-webkit-transform': 'translate(0, 0)'})
//     }
// }


/* scroll effects */
$(window).scroll(function() {
  if($('.testimonial-heading').offset()){
    var hT = $('.testimonial-heading').offset().top,
    hH = $('.testimonial-heading').outerHeight(),
    wH = $(window).height(),
    wS = $(this).scrollTop();
    if (wS > (hT+hH-wH)){
        $('.testimonial-carousel').css({'opacity': '1', 'transform':'translate(0, 0)', '-ms-transform': 'translate(0, 0)' ,
    '-webkit-transform': 'translate(0, 0)'})
    }
  }

});
$(window).scroll(function() {
  if($('.services-container').offset()){
    var hT2 = $('.services-container').offset().top,
    hH2 = $('.services-container').outerHeight(),
    wH2 = $(window).height(),
    wS2 = $(this).scrollTop();
    if (wS2 > (hT2+hH2-wH2)){
        $('.our-work-carousel').css({'opacity': '1', 'transform':'translate(0, 0)', '-ms-transform': 'translate(0, 0)' ,
    '-webkit-transform': 'translate(0, 0)'})
    }
  }

});
$(window).scroll(function() {
  if($('.video-section').offset()){
    var hT3 = $('.video-section').offset().top,
    hH3 = $('.video-section').outerHeight(),
    wH3 = $(window).height(),
    wS3 = $(this).scrollTop();
    if (wS3 > (hT3+hH3-wH3)){
        $('.contact-info-block').css({'opacity': '1', 'margin-left': '0'})
        $('.contact-form').css({'opacity': '1', 'margin-right': '0'})
    }
  }

});

