import { Component, OnInit } from '@angular/core';
import { ScreenService } from 'src/app/services/screen.service';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

declare var $: any;

@Component({
  selector: 'app-latest-project-gallery',
  templateUrl: './latest-project-gallery.component.html',
  styleUrls: ['./latest-project-gallery.component.css']
})
export class LatestProjectGalleryComponent implements OnInit {

  imgURLprefix ='';
  latestProjectsList = [
    {id: 1, name: 'Fender Selection Tool', img: 'Fender-Tools-big.png'},
    {id: 2, name: 'Customer in Focus', img: 'Customer-in-focus-big.png'},
    {id: 3, name: 'IntelliStok', img: 'Intellistok-big.png'},
    {id: 4, name: 'Sales Excellence Project', img: 'Sales-Excellence-big.png'},
    {id: 5, name: 'Software Configuration Kit', img: 'Software-configuration-kit-big.png'},
    {id: 6, name: 'Technical Proposals Online', img: 'Technical-proposals-online-big.png'},
    {id: 7, name: 'Shim Selector', img: 'Shim-selector-big.png'},
    {id: 8, name: 'ActiviTEE (OEE Monitoring)', img: 'OEE.png'},
    {id: 9, name: 'Augmented Reality App', img: 'AR.png'},
    {id: 10, name: 'Seals-Shop Europe', img: 'Sealshopcs.png'},
  ]

  constructor(private screenService: ScreenService,) {
    this.imgURLprefix = this.screenService.getPathToImage();
  }

  ngOnInit() {  
  }

  ngAfterViewInit(): void {  
    /* slider */
    $('.projects-carousel').owlCarousel({
      loop:true,
      autoplay: true,
      responsiveClass:true,
      responsive:{
          0:{
              items:1,
              // nav:true
          }
      }
    });    
  }

}
