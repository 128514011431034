import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenService } from 'src/app/services/screen.service';

declare var $: any;

@Component({
  selector: 'app-seal-shop',
  templateUrl: './seal-shop.component.html',
  styleUrls: ['./seal-shop.component.css']
})

export class SealShopComponent implements OnInit {

  imgURLprefix: string = '';

  constructor(private screenService: ScreenService, private router: Router) {
    this.imgURLprefix = this.screenService.getPathToImage();
  }


  ngOnInit() {
    /* stop yputube videos */
    $('#myVideoModal .close').on('click', function () {
      $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
    });

    $('#myVideoModal').on('hidden.bs.modal', function () {
      $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
    });

    $('html, body').animate({
      scrollTop: -100
    }, 1);
  }

  scroll(id) {
    if (id == 'contactUs') {
      this.screenService.setSelectedParam(true);
    } else {
      this.screenService.setSelectedParam(false);
    }
    this.router.navigate(['']);
  }

}
