import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Resolve } from '@angular/router';
import { ResponseContentType } from "file-saver";
import { saveAs } from 'file-saver/FileSaver';
import { debug } from 'util';

@Injectable({
    providedIn: 'root'
})
export class DataService implements Resolve<Object>{

    languageData: any;
    languageDataSubject = new BehaviorSubject("");
    translateJsonUrl = '';
    BACKEND_PATH = '';

    constructor(private http: HttpClient) {        
        this.BACKEND_PATH = this.getPathToService();
        this.translateJsonUrl = this.getPathToi18nJson();
        this.loadLanguageData();
    }

    getPathToService() {
        let addrURL = window.location.href;
        if (addrURL.length > 0) {
            if (addrURL.indexOf("/dev/") > -1) {
                return "/apps/dev/idc";
            }
            else if (addrURL.indexOf("/qc/") > -1) {
                return "/apps/qc/idc";
            }
            else if (addrURL.indexOf("/apps/idc") > -1) {
                return "/apps/idc";
            }
            else if (addrURL.indexOf("localhost") > -1) {
                return "http://localhost:8080/MicroSiteApp";
            }
            else {
                return "";
            }
        }
    }

    getPathToi18nJson() {
        let addrURL = window.location.href;
        if (addrURL.length > 0) {
            if (addrURL.indexOf("/dev/") > -1) {
                return "/apps/dev/idcinnovation/assets/i18n/";
            }
            else if (addrURL.indexOf("/qc/") > -1) {
                return "/apps/qc/idcinnovation/assets/i18n/";
            }
            else if (addrURL.indexOf("/apps/idc") > -1) {
                return "/apps/idc/assets/i18n/";
            }
            else if (addrURL.indexOf("localhost") > -1) {
                return "/assets/i18n/";
            }
            else {
                return "/assets/i18n/";
            }
        }
    }

    resolve() {
        return this.languageDataSubject.toPromise();
    }

    loadLanguageData() {
        return this.http.get(`${this.translateJsonUrl}en.json`).subscribe(d => {
            this.languageData = d
            this.languageDataSubject.complete();
        });
    }
    translate(field) {
        if (this.languageData && field) {
            let keys = field.split('.');
            let o = this.languageData[keys[0]];
            for (let i = 1; i < keys.length; i++) {
                o = o[keys[i]];
            }
            return o;
        } else {
            return "BAD TRANSLATION DATA";
        }
    }

}