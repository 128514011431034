import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ScreenService } from 'src/app/services/screen.service';

declare var $: any;

@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.css']
})
export class WebComponent implements OnInit {
 
  imgURLprefix ='';
  constructor( private screenService: ScreenService, private router: Router) {
    this.imgURLprefix = this.screenService.getPathToImage();
   }

  ngOnInit() {
    /* stop yputube videos */
    $('#myVideoModal .close').on('click', function(){      
        $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
    });
    
    $('#myVideoModal').on('hidden.bs.modal', function () {
      $('#myVideoModal .modal-body iframe').attr('src', $('#myVideoModal .modal-body iframe').attr('src'));
    });

    $('html, body').animate({
      scrollTop: -100
    }, 1);

    /* open t-talk video stream in new tab */
    // $('.play-button').click(function () {
    //     var redirectWindow = window.open('https://web.microsoftstream.com/channel/fed4d15b-38c1-4d19-9b09-938cb67ad4ad', '_blank');
    //     redirectWindow.location;
    // });
  }

  scroll(id) {    
    if(id== 'contactUs') {
      this.screenService.setSelectedParam(true); 
    } else{
      this.screenService.setSelectedParam(false); 
    }
    this.router.navigate(['']);
  }


}