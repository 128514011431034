import { Component, OnInit } from '@angular/core';
import { ScreenService } from 'src/app/services/screen.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-work-statistics-showcase',
  templateUrl: './work-statistics-showcase.component.html',
  styleUrls: ['./work-statistics-showcase.component.css']
})
export class WorkStatisticsShowcaseComponent implements OnInit {

  imgURLprefix ='';
  projectStats = {
      "completedWebProjects": 55,
      "completedMobileProjects": 34,
      "supportedWebApps": 41,
      "supportedMobileApps": 58
  }
  
  constructor( private screenService: ScreenService, private dataService: DataService) {
    this.imgURLprefix = this.screenService.getPathToImage();
  }

  ngOnInit() {
  }

}
